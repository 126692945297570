import componentFactoryFrom from './factory';
import types from './component-types';
import totalResults from '../../components-v2/sidebar/components/search-result';

const sources = {};

sources[types.TOTAL_RESULTS] = totalResults;

const ComponentFactory = componentFactoryFrom(sources);

export default ComponentFactory;
