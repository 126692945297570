import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import classnames from 'classnames';

import useEshopsEnvironment from '../../hooks/use-eshops-environment';
import IconFull from '../../components-v2/icons/sources/full';
import ComponentFactory from '../factory/factory.mobile';
import { useResults } from '../../components-v2/layout/hooks/use-results';

const namespace = 'ui-search-main-header-exp';

const labelBuilder = (text, values) => {
  if (values) {
    const { key } = values[0];

    return text.split(' ').map((word) =>
      word === `{${key}}` ? (
        <span className="content-icon">
          <IconFull className="mr-5" />
        </span>
      ) : (
        `${word} `
      ),
    );
  }

  return text;
};

const MainHeader = ({ h1, linkH1, subtitle, values, totalResults }) => {
  const { currentLayout, layout_options } = useResults();
  const { isEshopsEnvironment: isEshop } = useEshopsEnvironment();
  const current = currentLayout === undefined ? layout_options.current : currentLayout.id;

  return h1 ? (
    <div
      className={classnames(namespace, `${namespace}--${current}`, {
        [`${namespace}--eshop--title`]: isEshop,
      })}
    >
      {subtitle && <div className={`${namespace}__subtitle`}>{subtitle}</div>}
      <h1 className={`${namespace}__title`}>
        {linkH1 ? (
          <a className={`${namespace}__link`} href={linkH1}>
            {h1}
          </a>
        ) : (
          labelBuilder(h1, values)
        )}
      </h1>
      {totalResults && <ComponentFactory namespace={namespace} type={totalResults?.type} text={totalResults?.text} />}
    </div>
  ) : null;
};

MainHeader.propTypes = {
  h1: string,
  linkH1: string,
  subtitle: string,
  totalResults: shape({
    type: string,
    text: string,
  }),
  values: arrayOf(
    shape({
      key: string,
    }),
  ),
};

export default MainHeader;
export { MainHeader };
